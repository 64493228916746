import { branding, universalMargin } from 'styles';
import { useShopProgressState } from 'components/Shop/SubmitProgressState';
import { FormBuilder } from 'components/forms';
import { useTrackCustomEvent, ciscoCountries, useSiteMetadata, isOnServer } from 'utils';
import { useHandleSubmit } from 'components/forms/useHandleSubmit';
import React, { useEffect } from 'react';
import { useQuote } from 'context/Quote/QuoteContext';
import { getEmailFormattedQuote } from 'context/Quote/utils/getSimplifiedQuote';
import styled from 'styled-components';

/**
 * Renders out the order form w/ submit handling
 */
export function OrderForm() {
  const { siteUrl } = useSiteMetadata();
  const { quote, quoteId, dispatchQuote } = useQuote();
  const { submitState, handleSubmit } = useHandleSubmit(branding.formApiEndpoint);

  /** Custom Event Tracking */
  const trackCustomEvent = useTrackCustomEvent();
  useEffect(() => {
    switch (submitState.status) {
      case 'success':
        trackCustomEvent({
          category: 'Contact Form',
          action: '`Send request` response.ok',
          label: window.location.pathname,
        });
        break;

      case 'sending':
        trackCustomEvent({
          category: 'Contact Form',
          action: '`Send request` clicked',
          label: window.location.pathname,
        });
        break;

      case 'error':
        trackCustomEvent({
          category: 'Contact Form',
          action: '`Send request` failed',
          label: `${window.location.pathname} - ${submitState.message}`,
        });
        break;
    }
  }, [submitState, trackCustomEvent]);

  /** Effect that updates progress state, when submit state is changed */
  const [, setProgressState] = useShopProgressState();
  useEffect(() => setProgressState(submitState), [setProgressState, submitState]);

  /** Effect that resets shopping cart, on successful order */
  useEffect(() => {
    if (submitState.status === 'success') dispatchQuote({ type: 'reset' });
  }, [dispatchQuote, submitState]);

  return (
    <StyledOrderForm>
      {submitState.status === 'ready' && (
        <>
          <Description>
            Please fill out the form below and a sales representative will be in touch shortly with a personalised
            quote. Highlighted fields are required.
          </Description>

          <FormBuilder
            fields={{
              firstName: { type: 'text', label: 'First name', required: true },
              lastName: { type: 'text', label: 'Last name', required: true },
              company: { type: 'text', label: 'Business/Company' },
              customerId: { type: 'text', label: 'Customer ID (optional)' },
              address1: { type: 'text', label: 'Address Line 1', required: true },
              address2: { type: 'text', label: 'Address Line 2' },
              stateProv: { type: 'text', label: 'State / Province', required: true },
              city: { type: 'text', label: 'City', required: true },
              zipPostal: { type: 'text', label: 'Zip Code', required: true },
              country: { type: 'select', label: 'Country', required: true, options: ciscoCountries },
              busPhone: { type: 'tel', label: 'Phone Number', required: true, placeholder: '(+Code) 123 21 123' },
              emailAddress: { type: 'email', label: 'E-mail', required: true },
              comments: { type: 'textarea', label: 'Notes' },
              elqFormName: { type: 'hidden' },
              elqSiteId: { type: 'hidden' },
              campaign: { type: 'hidden' },
              clickedUrl: { type: 'hidden' },
            }}
            initialValues={{
              firstName: undefined,
              lastName: undefined,
              company: undefined,
              customerId: undefined,
              address1: undefined,
              address2: undefined,
              stateProv: undefined,
              city: undefined,
              zipPostal: undefined,
              country: undefined,
              busPhone: undefined,
              emailAddress: undefined,
              comments: undefined,
              elqFormName: branding.elqFormNameQuote,
              elqSiteId: branding.formSiteId,
              campaign: branding.formCampaignId,
              clickedUrl: isOnServer ? 'inserverenv' : window.location.href,
            }}
            onSubmit={value => {
              /** We hook into the submit handler, to add the quote to the description1 field.  */

              const description1 = JSON.stringify(
                {
                  quoteId,
                  quote: getEmailFormattedQuote(quote),
                  comments: value.comments,
                  customerId: value.customerId,
                  siteOrigin: siteUrl,
                },
                null,
                2
              );

              handleSubmit({ ...value, description1 });
            }}
          />
        </>
      )}
      {submitState.status === 'sending' && <Heading>Sending...</Heading>}
    </StyledOrderForm>
  );
}

const StyledOrderForm = styled.div`
  padding: ${universalMargin * 4}px 0;
`;

const Heading = styled.h2`
  margin-bottom: ${universalMargin * 4}px;
`;

const Description = styled.p`
  margin-bottom: ${universalMargin * 4}px;
`;
