import React from 'react';
import Icon from './assets/check_24.svg';
import styled from 'styled-components';
import { branding, universalMargin } from 'styles';
import { grey70, grey40 } from 'styles/baseColors';

interface StepProps {
  title: string;
  highlighted: boolean;
  completed: boolean;
}

export default function Step(props: StepProps) {
  return (
    <Root highlighted={props.highlighted}>
      <Dot highlighted={props.highlighted}>{props.completed && <Icon />}</Dot>
      <Title>{props.title}</Title>
    </Root>
  );
}

const Root = styled.div<{ highlighted?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  position: relative;

  :not(:first-child)::before {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    background-color: ${grey40};
    height: 3px;
    width: calc(100% + 30px);
    right: 50%;
    top: 10px;
  }

  ${({ highlighted }) =>
    highlighted &&
    `
    :not(:first-child)::before {
      background-color: ${branding.accentColor};
    }
  `}
`;

const Dot = styled.div<{ highlighted?: boolean }>`
  background-color: ${props => (props.highlighted ? branding.accentColor : grey40)};
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-bottom: ${universalMargin * 5}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  color: ${grey70};
`;
