import React from 'react';
import styled from 'styled-components';
import { grey40, grey80, universalMargin, white, xsmallBody } from 'styles';

type OrderSectionHeadingProps = {
  heading: string;
  num?: number;
  borderBottom?: boolean;
  submenu?: React.ReactNode;
};

export default function OrderSectionHeading({ heading, num, borderBottom = true, submenu }: OrderSectionHeadingProps) {
  return (
    <StyledOrderSectionHeading borderBottom={borderBottom}>
      {num !== undefined && <span className={'number'}>{num}</span>}
      {heading}
      {submenu && <div className="submenu">{submenu}</div>}
    </StyledOrderSectionHeading>
  );
}

const StyledOrderSectionHeading = styled.h5<{ borderBottom: boolean }>`
  padding-top: ${universalMargin * 8}px;
  padding-bottom: ${universalMargin * 2}px;
  text-align: left;
  ${props => props.borderBottom && `border-bottom: solid 1px ${grey40};`}
  width: 100%;

  .number {
    ${xsmallBody}
    background-color: ${grey80};
    color: ${white};
    border-radius: 75%;
    padding: 6px 10px;
    margin-right: ${universalMargin}px;
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: middle;
  }

  .submenu {
    float: right;
  }
`;
