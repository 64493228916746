import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import getRoute from 'routes/getRoute';
import { ProductInCart } from 'context/Quote/typedefs';
import { anchor, mediaQueries, mediumBody, xsmallBody } from 'styles';

interface Props {
  quoteProduct: ProductInCart;
  isEditable?: boolean;
}

export function Title(props: Props) {
  let productHeading =
    props.quoteProduct.product?.selectedVariant?.heading ||
    props.quoteProduct.product.shortHeading ||
    props.quoteProduct.product.heading;

  return props.isEditable ? (
    <TitleSpan as={Link} to={getRoute(props.quoteProduct.product)}>
      {productHeading}
    </TitleSpan>
  ) : (
    <TitleSpan>{productHeading}</TitleSpan>
  );
}

let TitleSpan = styled.span`
  > a {
    ${mediumBody};
    ${anchor};
    display: inline-block;
    position: relative;
    margin-bottom: 0.4rem;
    text-decoration: underline solid currentColor;
    text-decoration-thickness: 0.06rem;
  }
  .addButton {
    display: none;
    ${mediaQueries.small(
      css`
        display: inline-block;
        ${xsmallBody};
        border-radius: 10px;
        padding: 5px 9px 2px;
        margin-left: 20px;
      `
    )}
  }
`;
