import { ReactNode } from 'react';
import { BLOCKS, INLINES, MARKS, Block, Inline } from '@contentful/rich-text-types';
import { Options as ContentfulRichtTextRendererOptions } from '@contentful/rich-text-react-renderer';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
  renderRichText,
} from 'gatsby-source-contentful/rich-text';

// FormBuilder: Property 'references' is missing in type 'RichText' but required in type 'RenderRichTextData<ContentfulRichTextGatsbyReference>'.
export function extractRichTextStrings<T extends ContentfulRichTextGatsbyReference>(
  cmsRichText: RenderRichTextData<T> | undefined
) {
  if (!cmsRichText) return '';
  const options: ContentfulRichtTextRendererOptions = {
    renderText: renderText,
    renderNode: {
      ...escapeList,
      [BLOCKS.PARAGRAPH]: flattenParagraph,
      [INLINES.ENTRY_HYPERLINK]: extractReferenceStringContent,
      [INLINES.HYPERLINK]: extractReferenceStringContent,
      [INLINES.ASSET_HYPERLINK]: extractReferenceStringContent,
      [BLOCKS.EMBEDDED_ASSET]: removeContent, // if we got any embedded assets, we'll remove it altogether
    },
    renderMark: {
      [MARKS.BOLD]: escapeMarkMarkup,
      [MARKS.CODE]: escapeMarkMarkup,
      [MARKS.ITALIC]: escapeMarkMarkup,
      [MARKS.UNDERLINE]: escapeMarkMarkup,
    },
  };

  let renderedString = renderRichText(cmsRichText, options);
  let text = Array.isArray(renderedString) ? (renderedString as Array<ReactNode>).flat(2).join(`\n`) : renderedString;
  return text ? text.toString() : '';
}

function renderText(text: string) {
  return text;
}

// the extracted data arrives at the flattenParagrah children arg as an array of text string
function flattenParagraph(node: Block | Inline, children: ReactNode): Block | Inline | Text {
  return Array.isArray(children) ? children.flat(2).find(node => Boolean(node)) : children;
}

function extractReferenceStringContent(node: Block | Inline) {
  let [content] = node?.content;
  return content;
}

// returning only the children and not the `node` we extract the (string) content of a element and can discard the wrapping markup
function escapeBlockMarkup(node: Block | Inline, children: JSX.Element) {
  return children;
}

// returning only the children and not the `node` we extract the (string) content of a element and can discard the wrapping markup
function escapeMarkMarkup(text: ReactNode): ReactNode {
  return text;
}

// for some content we don't have title or meta information to be able to put anything with sense back into the string
function removeContent(node: Block | Inline, children: ReactNode) {
  return null;
}

const escapeList: Record<string, (node: Block | Inline, children: JSX.Element) => ReactNode> = {
  [BLOCKS.HEADING_1]: escapeBlockMarkup,
  [BLOCKS.HEADING_2]: escapeBlockMarkup,
  [BLOCKS.HEADING_3]: escapeBlockMarkup,
  [BLOCKS.HEADING_4]: escapeBlockMarkup,
  [BLOCKS.HEADING_5]: escapeBlockMarkup,
  [BLOCKS.HEADING_6]: escapeBlockMarkup,
  [BLOCKS.OL_LIST]: escapeBlockMarkup,
  [BLOCKS.UL_LIST]: escapeBlockMarkup,
  [BLOCKS.LIST_ITEM]: escapeBlockMarkup,
  [BLOCKS.HR]: escapeBlockMarkup,
  [BLOCKS.QUOTE]: escapeBlockMarkup,
  [BLOCKS.EMBEDDED_ENTRY]: escapeBlockMarkup,
  [BLOCKS.EMBEDDED_ASSET]: escapeBlockMarkup,
};
