import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';
import { SubmitState } from 'components/forms/useHandleSubmit';

const ShopProgressStateContext = createContext<[SubmitState, React.Dispatch<React.SetStateAction<SubmitState>>]>([
  { status: 'ready' },
  (() => undefined) as Dispatch<SetStateAction<SubmitState>>,
]);

type SubmitProgressStateProviderProps = { children: ReactNode };

export default function SubmitProgressStateProvider({ children }: SubmitProgressStateProviderProps) {
  const progressStateStore = useState<SubmitState>({ status: 'ready' });
  return <ShopProgressStateContext.Provider value={progressStateStore}>{children}</ShopProgressStateContext.Provider>;
}

export function useShopProgressState() {
  return useContext(ShopProgressStateContext);
}
