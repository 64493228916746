import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Page from 'components/Page';
import { SiteMetadata } from 'white-label';
import { ContentfulPage, PageSections } from 'types';

type OrderFailedData = {
  successFailedPage: ContentfulPage & PageSections;
  site: { siteMetadata: Pick<SiteMetadata, 'image'> };
};

export default function SubmitFailedPage({ path }: RouteComponentProps) {
  const { successFailedPage } = useStaticQuery<OrderFailedData>(successFailedQuery);
  return <Page sections={successFailedPage.sections} />;
}

const successFailedQuery = graphql`
  query {
    successFailedPage: contentfulPage(contentful_id: { eq: "4mupQ8LApzNqJj7mjhuFip" }) {
      ...HomeContent
    }
    site {
      siteMetadata {
        image
      }
    }
  }
`;
