import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Page from 'components/Page';
import { ContentfulPage, PageSections } from 'types';
import { SiteMetadata } from 'white-label/shared/types';
import { RouteComponentProps } from '@reach/router';

type OrderSuccessData = {
  successOrderPage: ContentfulPage & PageSections;
  site: { siteMetadata: Pick<SiteMetadata, 'image'> };
};

export default function SubmitSuccessPage({ path }: RouteComponentProps) {
  const { successOrderPage } = useStaticQuery<OrderSuccessData>(successOrderQuery);
  return <Page sections={successOrderPage.sections} />;
}

const successOrderQuery = graphql`
  query {
    successOrderPage: contentfulPage(contentful_id: { eq: "54YTyykplOZlhARzDyDihB" }) {
      ...HomeContent
    }
    site {
      siteMetadata {
        image
      }
    }
  }
`;
