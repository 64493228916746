import { PaymentType, ProductInCart, ProductWithSelectedVariant } from 'context/Quote/typedefs';
import { getProductPrice } from 'context/Quote/utils';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { mediaQueries, mediumBody } from 'styles';

import { assertUnreachable, toCurrencyString } from 'utils';

interface PriceTagProps {
  paymentType: PaymentType | 'hidden';
  quoteProduct: ProductInCart | ProductWithSelectedVariant; //? can it ever be ProductWithSelectedVariant? Can it be only a Product (not being a ProductWithVariant)? //?
}

export function PriceTag({ quoteProduct, paymentType }: PriceTagProps) {
  const product = quoteProduct.__typename === 'ProductWithSelectedVariant' ? quoteProduct : quoteProduct.product;
  const quantity = quoteProduct.__typename === 'ProductWithSelectedVariant' ? 1 : quoteProduct.quantity;

  // const itemMonthlyPrice = selectedVariant?.monthlyPrice && selectedVariant?.monthlyPrice > 0 ? selectedVariant?.monthlyPrice : product?.monthlyPrice; const monthlyPrice = itemMonthlyPrice * quantity;
  const itemPrice = getProductPrice(product);
  const formattedPrice = useMemo(() => toCurrencyString(itemPrice * quantity), [itemPrice, quantity]);
  // const formattedMonthlyPrice = useMemo(() => toMonthlyCurrencyString(monthlyPrice), [monthlyPrice]);

  switch (paymentType) {
    // case 'Monthly Payments': return <span>{formattedMonthlyPrice}</span>;
    case 'Placed Order':
    case 'Quote':
      return <PriceSpan>{formattedPrice}</PriceSpan>;
    case 'hidden':
      return null;

    default:
      assertUnreachable(paymentType);
  }
}

let PriceSpan = styled.span`
  ${mediumBody};
  display: block;
  ${mediaQueries.medium(css`
    display: unset;
  `)}
  text-align: right;
  white-space: nowrap;
`;
