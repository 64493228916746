import React from 'react';
import { toCurrencyString } from 'utils/toCurrencyString';
import { OrderListProductRow } from './OrderListProductRow';
import { useQuote } from 'context/Quote/QuoteContext';
import { Quote } from 'context/Quote/typedefs';
import styled, { css } from 'styled-components';
import { grey60, h5Heading, largeBody, mediaQueries, universalMargin, white } from 'styles';

export type OrderListType = 'editable' | 'full' | 'short';

interface OrderListProps {
  listType: OrderListType;
}

export default function OrderList(props: OrderListProps) {
  let { quote, placedOrderTotal, requestQuoteTotal } = useQuote();

  return (
    <QuoteList className={props.listType}>
      <OrderListTable className={props.listType}>
        <OrderListTableBody>
          <OrderListRows
            items={quote.filter(item => item.paymentType === 'Placed Order')}
            total={placedOrderTotal}
            listType={props.listType}
          />
          <OrderListRows
            items={quote.filter(item => item.paymentType === 'Quote')}
            total={requestQuoteTotal}
            listType={props.listType}
          />
        </OrderListTableBody>
      </OrderListTable>
    </QuoteList>
  );
}

interface OrderListRowsProps {
  items: Quote;
  total: number;
  listType: OrderListType;
}
function OrderListRows(props: OrderListRowsProps) {
  if (!props.items.length) return null;

  return (
    <>
      <PaymentType>
        <PaymentTypeName colSpan={props.listType === 'short' ? 1 : 2}>Quote request</PaymentTypeName>
        <HeaderTotal>Total</HeaderTotal>
        <Total>{toCurrencyString(props.total)}</Total>
      </PaymentType>
      {props.items.map((item, index) => (
        <OrderListProductRow
          quoteProduct={item}
          key={`${JSON.stringify(item)}-${index}`}
          listType={props.listType}
          paymentType="Quote"
        />
      ))}
    </>
  );
}

export let OrderListTable = styled.table`
  width: 100%;
  &.short {
    padding: ${universalMargin * 2}px 0;
  }
`;

export let OrderListTableBody = styled.tbody`
  display: block;
  ${mediaQueries.medium(css`
    display: table-row-group;
  `)}
`;

let PaymentType = styled.tr`
  display: block;

  ${OrderListTable}.short & {
    background-color: ${grey60};
    color: ${white};
  }

  ${mediaQueries.medium(css`
    display: table-row;
  `)}
`;

let Td = styled.td`
  display: inline-block;
  width: 50%;
  ${mediaQueries.medium(css`
    display: table-cell;
    width: unset;
    white-space: nowrap;
  `)}
  ${OrderListTable}.short & {
    padding: ${universalMargin * 0.5}px ${universalMargin}px;
  }
`;

let PaymentTypeName = styled(Td)`
  ${OrderListTable}.editable & {
    ${h5Heading}
    padding-top: ${universalMargin * 4}px;
    padding-bottom: ${universalMargin * 2}px;
  }
`;

let HeaderTotal = styled(Td)`
  text-align: center;
  display: none;
  ${mediaQueries.medium(css`
    display: table-cell;
  `)}

  ${OrderListTable}.editable & {
    ${largeBody}
    white-space: nowrap;
  }
`;

let Total = styled(Td)`
  text-align: right;
`;

let QuoteList = styled.div`
  padding: ${universalMargin * 4}px 0;

  ${mediaQueries.large(css`
    padding: ${universalMargin * 8}px 0;
  `)}

  &.short {
    padding: 0 0 ${universalMargin * 2}px 0;
  }
`;
