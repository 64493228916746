import React from 'react';
import { ProductInCart, PaymentType } from 'context/Quote/typedefs';
import { OrderListType } from '../OrderList';
import styled, { css } from 'styled-components';
import { breakpoints, grey40, mediaQueries, universalMargin } from 'styles';
import { Quantity, PriceTag, RemoveProduct } from '.';
import { Title } from './Title';
import { Thumbnail } from './Thumbnail';

interface OrderListProductRowProps {
  quoteProduct: ProductInCart; //? | ProductWithVariant;
  listType: OrderListType;
  paymentType?: PaymentType;
}

export function OrderListProductRow(props: OrderListProductRowProps) {
  const { product } = props.quoteProduct;
  const description = `${product?.shortDescription || ''}`;
  const isEditable = props.listType === 'editable';
  const isShort = props.listType === 'short';

  return (
    <ProductRow className={props.listType} title={description}>
      {!isShort && (
        <td className="thumbnail">
          {/* //? selectedVariant here? */}
          <Thumbnail image={product?.selectedVariant?.images?.[0].image || product?.gridImages?.[0].image} />
        </td>
      )}
      <td className="title">
        <Title isEditable={isEditable} quoteProduct={props.quoteProduct} />
      </td>
      <td className="quantity">
        <Quantity isEditable={isEditable} quoteProduct={props.quoteProduct} />
      </td>
      <td className="price">
        <PriceTag paymentType={props.paymentType || 'Quote'} quoteProduct={props.quoteProduct} />
      </td>

      {isEditable && (
        <td className="delete">
          <RemoveProduct quoteProduct={props.quoteProduct} />
        </td>
      )}
    </ProductRow>
  );
}

const ProductRow = styled.tr`
  border-top: solid 1px ${grey40};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: ${universalMargin * 2}px;

  font-size: 14px;
  line-height: 20px;
  ${mediaQueries.medium(css`
    font-size: 18px;
    line-height: 24px;
  `)}

  //td 1: Thumbnail .thumbnail {}

  //td 2: .title {}
  .title {
    order: 1;
    flex-basis: 50%;
  }
  // td 3: Quantity .quantity { }
  .quantity {
    order: 3;
    flex-basis: 75%;
  }
  // td 4: Price .price { }
  .price {
    order: 2;
    flex-basis: 10%;
  }
  // td 5: Remove button .delete { }
  .delete {
    order: 4;
    flex-basis: 40px;
    flex-grow: 0;
  }

  td {
    vertical-align: middle;
    padding-top: ${universalMargin * 0.5}px;
    padding-bottom: ${universalMargin}px;

    display: inline-block;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 3;
  }

  // 🧑‍🎤 👩‍🎤 👨‍🎤 // 👩‍🎤 // on mobile 📱:
  @media screen and (max-width: ${breakpoints.medium - 1}px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 4px;
    padding-top: 16px;

    // quote reset
    &&& td {
      padding-right: unset;
      padding-top: unset;
      padding-bottom: unset;
    }

    td:nth-child(1) {
      grid-column: 1 / span 3;
      grid-row: span 2;
      > div {
        width: unset !important;
        padding-right: unset;
        margin-right: unset !important;
        padding-top: unset;
        padding-bottom: unset;
      }
    }

    // name
    td:nth-child(2) {
      grid-column: span 6;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    // ꠹
    td:nth-child(3) {
      grid-column: 4 / span 6;
      grid-row: 2 / span 1;
    }

    // 💶
    td:nth-child(4) {
      grid-column: span 3;
    }

    td:nth-child(2),
    td:nth-child(4) {
      align-self: end;
    }
  }

  ${mediaQueries.medium(
    css`
      // Grid reset
      display: table-row;

      td {
        display: table-cell;
        padding-top: ${universalMargin}px;
        padding-bottom: ${universalMargin * 2}px;
      }

      // td 1: Thumbnail
      .thumbnail {
        width: 120px;
        padding-right: ${universalMargin * 2}px;
      }
    `
  )}

  &.short {
    td {
      padding: ${universalMargin}px ${universalMargin}px;
    }
  }
`;
