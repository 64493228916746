import React from 'react';
import { ProductInCart } from 'context/Quote/typedefs';
import OrderListQuantityModifier from './OrderListQuantityField';
import styled from 'styled-components';
import { universalMargin } from 'styles';

interface Props {
  quoteProduct: ProductInCart;
  isEditable?: boolean;
}

export function Quantity(props: Props) {
  return props.isEditable ? (
    <QuantityIndicator as={OrderListQuantityModifier} quoteProduct={props.quoteProduct} />
  ) : (
    <QuantityLabel>{`Qty: ${props.quoteProduct.quantity}`}</QuantityLabel>
  );
}

let QuantityIndicator = styled.span`
  text-align: left;
`;

let QuantityLabel = styled(QuantityIndicator)`
  text-align: center;
  white-space: nowrap;
  padding-right: ${universalMargin * 4}px;
`;
