import React, { ChangeEvent } from 'react';
import { DecrementButton, IncrementButton } from 'components/generic/Button';
import styles from './OrderListQuantityField.module.scss';
import { useQuote } from 'context/Quote/QuoteContext';
import { ProductInCart } from 'context/Quote/typedefs';

interface OrderListQuantityFieldProps {
  quoteProduct: ProductInCart; //? | ProductWithVariant;
}

export default function OrderListQuantityField({ quoteProduct }: OrderListQuantityFieldProps) {
  const { dispatchQuote } = useQuote();
  const { heading, shortHeading } = quoteProduct.product || {};
  const title = heading || shortHeading;
  const isDecrementable = quoteProduct.quantity > 1;

  const incrementQuantity = () =>
    dispatchQuote({
      type: 'increment',
      payload: {
        item: quoteProduct,
      },
    });

  const decrementQuantity = () =>
    dispatchQuote({
      type: 'decrement',
      payload: {
        item: quoteProduct,
      },
    });

  const setQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    dispatchQuote({
      type: 'setQuantity',
      payload: { item: quoteProduct, quantity: event.currentTarget.value || 1 },
    });
  };

  return (
    <div className={styles.quantity}>
      <div className={styles.fields}>
        <DecrementButton
          disabled={!isDecrementable}
          title={`Decrement ${title} quantity`}
          className={styles.quantityButton}
          clickHandler={decrementQuantity}
        />
        <input
          className={styles.quantityInput}
          type="number"
          title={`${title} quantity`}
          value={quoteProduct.quantity}
          onChange={setQuantity}
        />
        <IncrementButton
          disabled={false}
          title={`Increment ${title} quantity`}
          className={styles.quantityButton}
          clickHandler={incrementQuantity}
        />
      </div>
      <span className={styles.label}>qty</span>
    </div>
  );
}
