import React from 'react';
import { toCurrencyString } from '../../../utils/toCurrencyString';
import { useQuote } from '../../../context/Quote/QuoteContext';
import styled from 'styled-components';
import { universalMargin } from 'styles';
import OrderSectionHeading from '../sections/OrderSectionHeading';

export default function OrderTotals() {
  let { placedOrderTotal, requestQuoteTotal } = useQuote();
  return (
    <>
      <OrderSectionHeading heading={'Summary'} />
      <StyledOrderTotals>
        <tbody>
          <tr>
            <td>Direct Purchase</td>
            <td>{toCurrencyString(placedOrderTotal)}</td>
          </tr>
          <tr>
            <td>Quote request</td>
            <td>{toCurrencyString(requestQuoteTotal)}</td>
          </tr>
          <tr className={'salesTax'}>
            <td>Sales tax</td>
            <td>TBD</td>
          </tr>
          <tr className={'shipping'}>
            <td>Shipping</td>
            <td>TBD</td>
          </tr>
        </tbody>
      </StyledOrderTotals>
    </>
  );
}

const StyledOrderTotals = styled.table`
  width: 100%;

  td {
    white-space: nowrap;
    padding: ${universalMargin}px 0;
    &:last-of-type {
      text-align: right;
    }
  }

  .salesTax {
    padding-top: ${universalMargin * 2}px;
  }
`;
