import { Column } from 'components/generic/Grid';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'styles';

export const ReverseOrderColumn = styled(Column)`
  order: 2;
  ${mediaQueries.medium(css`
    order: unset;
  `)}
`;
