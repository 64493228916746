import styled, { css } from 'styled-components';
import Image from 'components/generic/Image';
import React from 'react';
import { Image as ImageType } from 'types';
import { mediaQueries, universalMargin } from 'styles';

interface Props {
  image: ImageType;
}

export function Thumbnail(props: Props) {
  return (
    <StyledImage
      objectFit="contain"
      objectPosition="center center"
      image={props.image?.gatsbyImageData}
      alt={props.image?.description}
    />
  );
}

let StyledImage = styled(Image)`
  ${mediaQueries.medium(css`
    width: 120px;
    height: 98px; // 16x9
    margin-right: ${universalMargin * 2}px;
    float: right;
  `)}
`;
