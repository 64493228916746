import LinkButton from '../../generic/LinkButton';
import OrderList from '../OrderList';
import PricingDisclaimer from '../../ProductList/PricingDisclaimer';
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Suspense } from 'utils';
import { branding, universalMargin } from 'styles';
import { isBuildTargetFord, isBuildTargetOnboarding } from 'white-label';
import OrderHeading from '../sections/OrderHeading';
import { ContentBlock } from 'components/generic/ContentBlock';
import { Column, Row } from 'components/generic/Grid';
import styled from 'styled-components';

const DownloadPdfLazy = React.lazy(() => import('../sections/DownloadPdfButton'));

export default function OrderSummaryPage({ uri }: RouteComponentProps) {
  return (
    <ContentBlock mobilePaddingY={'medium'} desktopPaddingY={'medium'}>
      <Row mobileRowGap="medium" desktopRowGap="large">
        <Column tablet="10/12" tabletOffset="2/12" desktop="10/12" desktopOffset="2/12">
          <OrderHeading heading={branding.order.summary.heading} />
          <OrderList listType={'editable'} />
          <LinkButton
            label={branding.order.summary.button}
            to={isBuildTargetFord() || isBuildTargetOnboarding() ? '/order/delivery-address' : `/order/submit`}
            action="Link"
            type="Primary"
            replace
          />
          {branding.order.summary.usePDFButton && (
            <PdfWrapper>
              <Suspense fallback={<div />}>
                <DownloadPdfLazy />
              </Suspense>
            </PdfWrapper>
          )}
          <PricingDisclaimer />
        </Column>
      </Row>
    </ContentBlock>
  );
}

const PdfWrapper = styled.div`
  padding: ${universalMargin * 4}px 0 0;
`;
