import React from 'react';
import Step from './Step';
import styled from 'styled-components';
import { universalMargin } from 'styles';

type StepType = {
  title: string;
};

interface StepProgressBarProps {
  steps: Array<StepType>;
  current: number;
}

export default function StepProgressBar({ steps, current }: StepProgressBarProps) {
  return (
    <Progress>
      {steps.map((step, index) => (
        <Step
          key={step.title + index}
          title={step.title}
          highlighted={index <= current}
          completed={index < current || current === steps.length - 1}
        />
      ))}
    </Progress>
  );
}

const Progress = styled.div`
  display: flex;
  gap: ${universalMargin * 4}px;
  position: relative;
`;
