import React from 'react';
import styled from 'styled-components';
import { useSiteMetadata } from 'utils';
import { universalMargin, xsmallBody } from 'styles';

export default function PricingDisclaimer({ className = '' }: { className?: string }) {
  const {
    phrases: { disclaimers },
  } = useSiteMetadata();

  return (
    <Wrapper className={className}>
      {disclaimers.map((body, index) => {
        const bullets = ''.padStart(index + 1, '*');
        return (
          <Paragraph key={body}>
            {bullets} {body}
          </Paragraph>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: ${universalMargin * 4}px;
  ${xsmallBody};
`;

const Paragraph = styled.p`
  font-size: 12px;
`;
