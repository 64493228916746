import React from 'react';
import { RemoveButton } from 'components/generic/Button';
import { useQuote } from 'context/Quote/QuoteContext';
import { ProductInCart } from 'context/Quote/typedefs';
import styled, { css } from 'styled-components';
import { mediaQueries, universalMargin } from 'styles';

interface RemoveProductProps {
  quoteProduct: ProductInCart; //? | ProductWithVariant;
}

export function RemoveProduct({ quoteProduct }: RemoveProductProps) {
  const { dispatchQuote } = useQuote();
  const { heading, shortHeading } = quoteProduct.product || {};
  const title = heading || shortHeading;
  let removeProduct = () => {
    dispatchQuote({
      type: 'remove',
      payload: {
        item: quoteProduct, //? seems like a Product is sent in, not a ProductInCart
      },
    });
  };

  return <StyledRemoveButton clickHandler={removeProduct} title={`Remove ${title}`} />;
}

let StyledRemoveButton = styled(RemoveButton)`
  text-align: right;
  width: 5%;
  padding-left: ${universalMargin * 2}px;
  ${mediaQueries.medium(
    css`
      text-align: center;
    `
  )}
`;
