import React from 'react';
import { Router } from '@reach/router';
import { graphql, PageProps } from 'gatsby';
import { ContentfulPage, PageSections } from 'types';
import { useQuote } from 'context/Quote/QuoteContext';
import Page from 'components/Page';
import SubmitProgressStateProvider from 'components/Shop/SubmitProgressState';
import OrderSummaryPage from 'components/Shop/OrderSummaryPage';
import PwpFormPage from 'components/Shop/PwpFormPage';

type OrderTemplateData = {
  emptyPageData: ContentfulPage & PageSections;
};

export default function OrderTemplate({ data, uri }: PageProps<OrderTemplateData>) {
  let { quote } = useQuote();

  if (quote.length <= 0) {
    return <Page sections={data.emptyPageData.sections} />;
  }

  return (
    <SubmitProgressStateProvider>
      <Router basepath={uri}>
        <OrderSummaryPage default />
        <PwpFormPage path="submit" />
      </Router>
    </SubmitProgressStateProvider>
  );
}

export const query = graphql`
  query ($emptyPageId: String!) {
    emptyPageData: contentfulPage(contentful_id: { eq: $emptyPageId }) {
      ...HomeContent
    }
  }
`;
