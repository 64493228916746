import React from 'react';
import styled from 'styled-components';
import { universalMargin } from 'styles';

type OrederHeadingProps = {
  heading: string;
};

export default function OrderHeading({ heading }: OrederHeadingProps) {
  return <StyledOrderHeading>{heading}</StyledOrderHeading>;
}

const StyledOrderHeading = styled.h3`
  width: 100%;
  padding-bottom: ${universalMargin}px;
`;
