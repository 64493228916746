import React, { ReactNode } from 'react';
import { mergeClassNames } from '../../../../../utils';
import Button from '../..';
import MinusIcon from './assets/minus_icon.svg';
import styles from './index.module.scss';

interface DecrementButtonProps {
  clickHandler?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title: string;
  children?: ReactNode;
  tabIndex?: number;
  className?: string;
  style?: object;
  disabled?: boolean;
}

export function DecrementButton({
  clickHandler,
  title,
  tabIndex,
  children,
  className,
  style,
  disabled,
}: DecrementButtonProps) {
  return (
    <Button
      disabled={disabled}
      title={title}
      tabIndex={tabIndex}
      startIcon={<MinusIcon className={styles.icon} />}
      clickHandler={clickHandler}
      className={mergeClassNames([styles.button, className])}
      style={style}
    >
      {children}
    </Button>
  );
}
