import { Link } from 'gatsby';
import { OrderForm } from 'components/forms';
import OrderList from '../OrderList';
import SubmitFailedPage from '../SubmitFailedPage';
import SubmitSuccessPage from '../SubmitSuccessPage';
import PricingDisclaimer from '../../ProductList/PricingDisclaimer';
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useShopProgressState } from '../SubmitProgressState';
import { useSiteMetadata } from '../../../utils/useSiteMetaData';
import StepProgressBar from '../../generic/StepProgressBar';
import OrderTotals from './OrderTotals';
import OrderHeading from '../sections/OrderHeading';
import OrderSectionHeading from '../sections/OrderSectionHeading';
import { ContentBlock } from 'components/generic/ContentBlock';
import { Column, Row } from 'components/generic/Grid';
import styled from 'styled-components';
import { ReverseOrderColumn } from '../sections/ReverseOrdercolumn';

const ProgressData = [
  { title: 'Create order' },
  { title: 'Submit order' },
  { title: 'Receive quote & payment' },
  { title: 'Track shipment' },
];

export default function PwpFormPage({ path }: RouteComponentProps) {
  let [shopProgressState] = useShopProgressState();
  let {
    orderPage: { url },
  } = useSiteMetadata();

  switch (shopProgressState.status) {
    case 'error':
      return <SubmitFailedPage />;
    case 'success':
      return <SubmitSuccessPage />;
    case 'ready':
    default:
      return <FormPageContent url={url} />;
  }
}

/**
 * @param {object} props
 * @param {string} props.url
 */
function FormPageContent({ url }) {
  return (
    <ContentBlock mobilePaddingY={'medium'} desktopPaddingY={'medium'}>
      <StepProgressBarWrapper>
        <StepProgressBar steps={ProgressData} current={1} />
      </StepProgressBarWrapper>
      <OrderHeading heading="Submit request" />
      <Row mobileRowGap="medium" desktopRowGap="large">
        <ReverseOrderColumn desktop="8/12">
          <OrderSectionHeading
            heading={'Submission overview'}
            num={1}
            borderBottom={false}
            submenu={<EditButton to={url}>Edit</EditButton>}
          />
          <OrderList listType={'short'} />
          <OrderSectionHeading heading={'Your information'} num={2} />
          <OrderForm />
          <PricingDisclaimer />
        </ReverseOrderColumn>
        <Column desktop="4/12" desktopOffset="9/12">
          <OrderTotals />
        </Column>
      </Row>
    </ContentBlock>
  );
}

const StepProgressBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: $universalMargin * 10;
`;

const EditButton = styled(Link)`
  float: right;
`;
